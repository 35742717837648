import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  submenuVisible: boolean = false;
  activeRoute?: string;

  submenuRodape = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'links-rodape/canais-digitais',
      label: 'Canais Digitais',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'links-rodape/servicos',
      label: 'Serviços',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'links-rodape/telefones',
      label: 'Telefones Úteis',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'links-rodape/redes-sociais',
      label: 'Redes Sociais',
    },
  ];

  submenuItemsParam = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'controle-parametros',
      label: 'Listar Parâmetros',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'controle-parametros/adicionar-parametro',
      label: 'Cadastrar Parâmetro',
    },
  ];

  submenuItemsBloq = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'controle-bloqueios',
      label: 'Todos os Bloqueios',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'controle-bloqueios/novo-bloqueio',
      label: 'Adicionar Bloqueio',
    },
  ];

  submenuPerg = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'perguntas-frequentes',
      label: 'Todas as Perguntas',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar',
      route: 'perguntas-frequentes/novo-perg',
      label: 'Adicionar Pergunta Frequente',
    },
  ];

  submenuGuia = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/categoria',
      label: 'Categoria',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/condicoes',
      label: 'Condições',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/documentos',
      label: 'Documentos',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/local-solicitante',
      label: 'Local Solicitante',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/preco',
      label: 'Preço',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/prazo',
      label: 'Prazo',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'guia-servicos/solicitante',
      label: 'Solicitante',
    },
  ];

  submenuNoticia = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'controle-noticia',
      label: 'Todas as notícias',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'controle-noticia/nova-noticia',
      label: 'Adicionar notícia',
    }
  ];

  subMenuCadUnico = [
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'cadastro-unico/upload',
      label: 'Envio de Arquivo',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'cadastro-unico/relatorio/upload',
      label: 'Relatório de Envio de Arquivo',
    },
    {
      active: false,
      iconSubmenu: 'Right-sidebar.svg',
      route: 'cadastro-unico/relatorio/cadastro',
      label: 'Relatório de Consulta',
    }
  ];


  menuItems = [
    {
      active: false,
      icon: 'icon-dashboard',
      route: 'dashboard',
      label: 'Dashboard',
    },
    {
      active: false,
      icon: 'control-user',
      route: 'gestao-usuario',
      label: 'Controle de Usuários',
    },
    {
      active: false,
      icon: 'control-param',
      label: 'Controle de Parâmetros',
      hasSubmenu: true,
      submenu: this.submenuItemsParam,
    },
    {
      active: false,
      icon: 'control-bloqueios',
      label: 'Controle de Bloqueios',
      hasSubmenu: true,
      submenu: this.submenuItemsBloq,
    },
    {
      active: false,
      icon: 'control-noticias',
      route: 'controle-noticias',
      label: 'Controle de Notícias',
      hasSubmenu: true,
      submenu: this.submenuNoticia
    },
    {
      active: false,
      icon: 'control-galeria',
      route: 'controle-galeria/lista-galeria',
      label: 'Controle de Galeria',
    }, 
    {
      active: false,
      icon: 'controle-cad-unico',
      label: 'Controle CadÚnico',
      hasSubmenu: true,
      submenu: this.subMenuCadUnico,
    },
    {
      active: false,
      icon: 'faq-admin',
      label: 'Perguntas Frequentes',
      hasSubmenu: true,
      submenu: this.submenuPerg
    },
    {
      active: false,
      icon: 'links-rodape',
      label: 'Controle de Links Rodapé',
      hasSubmenu: true,
      submenu: this.submenuRodape,
    },
    {
      active: false,
      icon: 'links-rodape',
      label: 'Controle de Guia de Serviços',
      hasSubmenu: true,
      submenu: this.submenuGuia,
    },
  ];

  routeActive: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.activeRoute =
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf('/') + 1
      ) ?? '';
    this.controlaMenu();
  }

  controlaItem(item: any) {
    if (item.hasSubmenu) {
      this.menuItems.forEach((m) => {
        m.active = false;
      });
      item.active = !item.active;
    } else {
      this.router.navigateByUrl(`/admin/${item.route}`);
    }
  }

  controlaSubItem(item: any) {
    this.menuItems.forEach((m) => {
      if (m.hasSubmenu) {
        m.submenu.forEach((s) => {
          s.active = false;
        });
      }
    });
    item.active = true;
    this.router.navigateByUrl(`/admin/${item.route}`);
  }

  rotaAtiva(route: string): boolean {
    const r = route.substring(route.lastIndexOf('/') + 1);
    return r.includes(`${this.activeRoute}`) ?? false;
  }

  controlaMenu() {
    this.menuItems.forEach((m) => {
      const r = m.route?.substring(m.route?.lastIndexOf('/') + 1);
      if (m.hasSubmenu) {
        m.submenu.forEach((s) => {
          const sr = s.route.substring(s.route.lastIndexOf('/') + 1);
          if (sr.includes(`${this.activeRoute}`)) {
            s.active = true;
            m.active = true;
          }
        });
      } else if (r?.includes(`${this.activeRoute}`)) {
        m.active = true;
      }
    });
  }
}
