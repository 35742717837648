// Components
import { AcessoMeusParcelamentosComponent } from './../modules/site-cliente/meus-parcelamentos/acesso-meus-parcelamentos/acesso-meus-parcelamentos.component';
import { InputDadosCartaoComponent } from '../modules/site-cliente/minha-conta/pagamento-recorrente/input-dadosCartao/input-dadosCartao.component';
import { SecurityCodeWarningComponent } from './components/cartao-dados-pagamento/security-code-warning/security-code-warning.component';
import { GerenciadorCookiesDialogComponent } from './components/gerenciador-cookies-dialog/gerenciador-cookies-dialog.component';
import { DialogPesquisaSastifacaoComponent } from './components/dialog-pesquisa-sastifacao/dialog-pesquisa-sastifacao.component';
import { DialogNovaAgvAvisoCNPJComponent } from './components/dialog-nova-agv-aviso-cnpj/dialog-nova-agv-aviso-cnpj.component';
import { PoliticaPrivacidadeComponent } from '../modules/site-cliente/politica-privacidade/politica-privacidade.component';
import { AgvInputSelectComponent } from './components/agv-material/agv-inputs/new-input-select/new-input-select.component';
import { SelecionarFornecimentoComponent } from './components/selecionar-fornecimento/selecionar-fornecimento.component';
import { AddressWarningComponent } from './components/selecionar-fornecimento/address-warning/address-warning.component';
import { MeusParcelamentosMenuComponent } from './components/meus-parcelamentos-menu/meus-parcelamentos-menu.component';
import { AgvInputMultSelect } from './components/agv-material/agv-inputs/input-mult-select/input-mult-select.component';
import { PaymentMethodsParcelamentoComponent } from './components/payment-methods-parc/payment-methods-parc.component';
import { ResumoProcessoComponent } from './components/agv-material/agv-resumo-processo/agv-resumo-processo.component';
import { InputTelefoneComponent } from './components/agv-material/agv-inputs/input-telefone/input-telefone.component';
import { CartaoDadosPagamentoComponent } from './components/cartao-dados-pagamento/cartao-dados-pagamento.component';
import { InputFormAdminComponent } from './components/input-form-admin/input-form-admin/input-form-admin.component';
import { SolicitacaoConcluidaComponent } from './components/solicitacao-concluida/solicitacao-concluida.component';
import { InputBuscaPrincipalComponent } from './components/input-busca-principal/input-busca-principal.component';
import { DialogNovaAgvAvisoComponent } from './components/dialog-nova-agv-aviso/dialog-nova-agv-aviso.component';
import { NotificacaoProcessoComponent } from './components/notificacao-processo/notificacao-processo.component';
import { PerguntasFrequentesComponent } from './components/perguntas-frequentes/perguntas-frequentes.component';
import { InputSelectComponent } from './components/agv-material/agv-inputs/input-select/input-select.component';
import { ResponsavelProcessoComponent } from './components/responsavel-processo/responsavel-processo.component';
import { InputImagemComponent } from './components/agv-material/agv-inputs/input-imagem/input-imagem.component';
import { InputNumberComponent } from './components/agv-material/agv-inputs/input-number/input-number.component';
import { ConfirmaPagtoComponent } from './components/pagamento-fatura/confirma-pagto/confirma-pagto.component';
import { FormInformacoesIncorretas } from './components/form-infos-incorretas/form-infos-incorretas.component';
import { ComunicaPagtoComponent } from './components/pagamento-fatura/comunica-pagto/comunica-pagto.component';
import { ModalExcluirCartaoComponent } from './components/modal-excluir-cartao/modal-excluir-cartao.component';
import { AgvButtonEditComponent } from './components/agv-material/agv-button/editar/agv-button-edit.component';
import { InputSelectEnderecoComponent } from './components/inputSelectEndereco/inputSelectEndereco.component';
import {
  GetStyleEnderecoComponent,
  ListaTipoCivico,
} from './components/endereco/getDadosEndereco.component';
import { ListaTarifasComponent } from '../modules/site-cliente/tarifas/lista-tarifas/lista-tarifas.component';
import { AgvButtonExcComponent } from './components/agv-material/agv-button/excluir/agv-button-exc.component';
import { VerificacaoUsuarioComponent } from './components/verificacao-usuario/verificacao-usuario.component';
import { SelectFornecimentoComponent } from './components/select-fornecimento/select-fornecimento.component';
import { RegisteredPasswordComponent } from './components/registered-password/registered-password.component';
import { SlideToggleComponent } from './components/agv-material/agv-slide-toggle/agv-slide-toggle.component';
import { ListaQuestionariosComponent } from './components/lista-questionarios/lista-questionarios.component';
import { InputEmailComponent } from './components/agv-material/agv-inputs/input-email/input-email.component';
import { DialogCancelamentoComponent } from './components/dialog-cancelamento/dialog-cancelamento.component';
import { DialogAvisoLogadoComponent } from './components/dialog-aviso-logado/dialog-aviso-logado.component';
import { AgvEnderecoImovelComponent } from './components/agv-endereco-imovel/agv-endereco-imovel.component';
import { CodigoBarrasComponent } from './components/pagamento-fatura/codigo-barras/codigo-barras.component';
import { InputTextComponent } from './components/agv-material/agv-inputs/input-text/input-text.component';
import { InputFileComponent } from './components/agv-material/agv-inputs/input-file/input-file.component';
import { InputDateComponent } from './components/agv-material/agv-inputs/input-date/input-date.component';
import { InputHoraComponent } from './components/agv-material/agv-inputs/input-hora/input-hora.component';
import { SucessoNovaSenhaComponent } from './components/sucesso-nova-senha/sucesso-nova-senha.component';
import { AgvDialogComponent } from './components/agv-material/agv-popup/agv-dialog/agv-dialog.component';
import { ChartHistConsumoComponent } from './components/chart-hist-consumo/chart-hist-consumo.component';
import { BoxCartoesSalvosComponent } from './components/box-cartoes-salvos/box-cartoes-salvos.component';
import { InputCepComponent } from './components/agv-material/agv-inputs/input-cep/input-cep.component';
import { CadastrarClienteComponent } from './components/cadastrar-cliente/cadastrar-cliente.component';
import { InputCpfComponent } from './components/agv-material/agv-inputs/input-cpf/input-cpf.component';
import { AgvItemListComponent } from './components/agv-material/agv-item-list/agv-item-list.component';
import { AcessibilidadeComponent } from './components/header/acessibilidade/acessibilidade.component';
import { EnderecoCorrespComponent } from './components/endereco-corresp/endereco-corresp.component';
import { PaginacaoTabelaComponent } from './components/paginacao-tabela/paginacao-tabela.component';
import { PagamentoFaturaComponent } from './components/pagamento-fatura/pagamento-fatura.component';
import { RedirecionaMapsComponent } from './components/redireciona-maps/redireciona-maps.component';
import { TabelaHistoricoComponent } from './components/tabela-historico/tabela-historico.component';
import { ChartIotDiarioComponent } from './components/chart-iot-diario/chart-iot-diario.component';
import { CardFaturaDesktop } from './components/card-fatura-desktop/card-fatura-desktop.component';
import { InputNovaSenhaComponent } from './components/input-nova-senha/input-nova-senha.component';
import { HeaderSearchComponent } from './components/header/header-search/header-search.component';
import { EnderecoImovelComponent } from './components/endereco-imovel/endereco-imovel.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { CardFaturaMobile } from './components/card-fatura-mobile/card-fatura-mobile.component';
import { PagtoAppComponent } from './components/pagamento-fatura/pagto-app/pagto-app.component';
import { PorEmailComponent } from './components/pagamento-fatura/por-email/por-email.component';
import { AgvButtonComponent } from './components/agv-material/agv-button/agv-button.component';
import { LoginMensagemComponent } from './components/login-mensagem/login-mensagem.component';
import { UploadArquivoComponent } from './components/upload-arquivo/upload-arquivo.component';
import { EtapaProcessoComponent } from './components/etapa-processo/etapa-processo.component';
import { FiltroFaturasComponent } from './components/filtro-faturas/filtro-faturas.component';
import { TabelaTarifasComponent } from './components/tabela-tarifas/tabela-tarifas.component';
import { InputMtdoPagComponent } from './components/input-mtdo-pag/input-mtdo-pag.component';
import { AgvTableComponent } from './components/agv-material/agv-table/agv-table.component';
import { NoticiaListaComponent } from './components/noticia-lista/noticia-lista.component';
import { AvisoCookiesComponent } from './components/aviso-cookies/aviso-cookies.component';
import { ButtonSabespComponent } from './components/button-sabesp/button-sabesp.component';
import { CheckoutComponent } from './components/checkout-cartao/checkout-cartao.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { AgvIconComponent } from './components/agv-material/agv-icon/agv-icon.component';
import { MenuServicoComponent } from './components/menu-servicos/menu-servico.component';
import { ModalInfoDocComponent } from './components/modalInfoDoc/modalInfoDoc.component';
import { FornecimentoComponent } from './components/fornecimento/fornecimento.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { HeaderAdminComponent } from './components/header-admin/header-admin.component';
import { ParcelDebtsComponent } from './components/parcel-debts/parcel-debts.component';
import { DialogErrorComponent } from './components/dialog-error/dialog-error.component';
import { TabelaAdminComponent } from './components/tabela-admin/tabela-admin.component';
import { InputBuscarComponent } from './components/input-buscar/input-buscar.component';
import { CadastroOcrComponent } from './components/cadastro-ocr/cadastro-ocr.component';
import { ModalOpcoesComponent } from './components/modal_opcoes/modal_opcoes.component';
import { CardInfoFornec } from './components/card-info-fornec/cardInfoFornec.component';
import { MensagemComponent } from './components/mensagem-erro/mensagem-erro.component';
import { LocalizacaoComponent } from './components/localizacao/localizacao.component';
import { CardSemFornec } from './components/card-sem-fornec/cardSemFornec.component';
import { ListaCheckComponent } from './components/lista-check/lista-check.component';
import { CardFaturaComponent } from './components/card-fatura/card-fatura.component';
import { TabelaCardComponent } from './components/tabela-card/tabela-card.component';
import { ListaDadosComponent } from './components/lista-dados/lista-dados.component';
import { SidenavComponent } from './components/navigation/sidenav/sidenav.component';
import { PerfilCnpjComponent } from './components/perfil-cnpj/perfil-cnpj.component';
import { AvisoTextoComponent } from './components/aviso-texto/aviso-texto.component';
import { IotChartJSComponent } from './components/chart-iot/iot-chart-js.component';
import { SidebarComponent } from '../modules/site-admin/sidebar/sidebar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ClickOutsideDirective } from '../core/directives/click-outside.directive';
import { ModalHomeComponent } from './components/modal-home/modal-home.component';
import { TabelaIotComponent } from './components/tabela-iot/tabela-iot.component';
import { ChartCnpjComponent } from './components/chart-cnpj/chart-cnpj.component';
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';
import { ValidaCpfComponent } from './components/valida-cpf/valida-cpf.component';
import { AGVStepperComponent } from './components/stepper/agv-stepper.component';
import { PagtoTableComponent } from './components/table/pagto-table.component';
import { PageSizeComponent } from './components/page-size/page-size.component';
import { EnderecoComponent } from './components/endereco/endereco.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChartJSComponent } from './components/chart-js/chart-js.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NoticiaComponent } from './components/noticia/noticia.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { TabelaComponent } from './components/tabela/tabela.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SelectComponent } from './components/select/select.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { LoginComponent } from './components/login/login.component';
import { ModalComponent } from './components/modal/modal.component';
import { TabComponent } from './components/tab/tab.component';
import { IotComponent } from './components/iot/iot.component';

// Modules
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputMaskModule } from 'primeng/inputmask';
import { TimelineModule } from 'primeng/timeline';
import { NgOtpInputModule } from 'ng-otp-input';
import { TabMenuModule } from 'primeng/tabmenu';
import { DividerModule } from 'primeng/divider';
import { RouterModule } from '@angular/router';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgModule } from '@angular/core';

// Pipe
import { EllipsisPipe } from './pipes/Ellipsis.pipe';
import { SafePipe } from './pipes/Safe.pipe';

// Outros
import { GetSelectFielStyle } from './components/form-infos-incorretas/selecStyleFormInfo.component';

@NgModule({
  declarations: [
    PaymentMethodsParcelamentoComponent,
    GerenciadorCookiesDialogComponent,
    DialogPesquisaSastifacaoComponent,
    AcessoMeusParcelamentosComponent,
    DialogNovaAgvAvisoCNPJComponent,
    SelecionarFornecimentoComponent,
    MeusParcelamentosMenuComponent,
    CartaoDadosPagamentoComponent,
    SolicitacaoConcluidaComponent,
    CartaoDadosPagamentoComponent,
    InputBuscaPrincipalComponent,
    InputSelectEnderecoComponent,
    PerguntasFrequentesComponent,
    PoliticaPrivacidadeComponent,
    NotificacaoProcessoComponent,
    ResponsavelProcessoComponent,
    SecurityCodeWarningComponent,
    SecurityCodeWarningComponent,
    DialogCancelamentoComponent,
    RegisteredPasswordComponent,
    SelectFornecimentoComponent,
    DialogNovaAgvAvisoComponent,
    VerificacaoUsuarioComponent,
    ListaQuestionariosComponent,
    ModalExcluirCartaoComponent,
    ModalExcluirCartaoComponent,
    DialogAvisoLogadoComponent,
    AgvEnderecoImovelComponent,
    BoxCartoesSalvosComponent,
    FormInformacoesIncorretas,
    CadastrarClienteComponent,
    SucessoNovaSenhaComponent,
    ChartHistConsumoComponent,
    InputDadosCartaoComponent,
    InputDadosCartaoComponent,
    BoxCartoesSalvosComponent,
    PaginacaoTabelaComponent,
    EnderecoCorrespComponent,
    PagamentoFaturaComponent,
    RedirecionaMapsComponent,
    TabelaHistoricoComponent,
    ChartIotDiarioComponent,
    InputFormAdminComponent,
    PaymentMethodsComponent,
    InputNovaSenhaComponent,
    ResumoProcessoComponent,
    AddressWarningComponent,
    AcessibilidadeComponent,
    EnderecoImovelComponent,
    InputFormAdminComponent,
    AgvInputSelectComponent,
    ComunicaPagtoComponent,
    ConfirmaPagtoComponent,
    EtapaProcessoComponent,
    UploadArquivoComponent,
    InputTelefoneComponent,
    FiltroFaturasComponent,
    LoginMensagemComponent,
    TabelaTarifasComponent,
    AgvButtonEditComponent,
    ModalInfoDocComponent,
    FornecimentoComponent,
    HeaderSearchComponent,
    CodigoBarrasComponent,
    ClickOutsideDirective,
    AvisoCookiesComponent,
    NoticiaListaComponent,
    ButtonSabespComponent,
    ListaTarifasComponent,
    InputMtdoPagComponent,
    SignaturePadComponent,
    InputMtdoPagComponent,
    AgvButtonExcComponent,
    InputImagemComponent,
    TabelaAdminComponent,
    MenuServicoComponent,
    HeaderAdminComponent,
    ParcelDebtsComponent,
    InputBuscarComponent,
    VideoDialogComponent,
    ModalOpcoesComponent,
    CadastroOcrComponent,
    DialogErrorComponent,
    InputSelectComponent,
    SlideToggleComponent,
    LocalizacaoComponent,
    InputNumberComponent,
    AgvItemListComponent,
    PerfilCnpjComponent,
    InputEmailComponent,
    CardFaturaComponent,
    ListaDadosComponent,
    BreadcrumbComponent,
    ListaCheckComponent,
    PagtoTableComponent,
    AGVStepperComponent,
    TabelaCardComponent,
    PaginationComponent,
    AvisoTextoComponent,
    IotChartJSComponent,
    ChartCnpjComponent,
    AgvButtonComponent,
    MenuAdminComponent,
    ValidaCpfComponent,
    InputTextComponent,
    AgvDialogComponent,
    ModalHomeComponent,
    InputDateComponent,
    InputFileComponent,
    AgvInputMultSelect,
    InputHoraComponent,
    TabelaIotComponent,
    InputCepComponent,
    PorEmailComponent,
    CarouselComponent,
    CheckboxComponent,
    MensagemComponent,
    CardFaturaDesktop,
    PagtoAppComponent,
    PageSizeComponent,
    EnderecoComponent,
    InputCpfComponent,
    CheckoutComponent,
    CheckoutComponent,
    AgvTableComponent,
    SidenavComponent,
    NoticiaComponent,
    CardFaturaMobile,
    LoadingComponent,
    SpinnerComponent,
    AgvIconComponent,
    ChartJSComponent,
    SidebarComponent,
    ChatbotComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    LayoutComponent,
    TabelaComponent,
    SelectComponent,
    LoginComponent,
    CardInfoFornec,
    PopUpComponent,
    ModalComponent,
    CardSemFornec,
    TabComponent,
    EllipsisPipe,
    IotComponent,
    SafePipe,
  ],
  imports: [
    NgMultiSelectDropDownModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatSidenavModule,
    NgOtpInputModule,
    HttpClientModule,
    NgxBarcodeModule,
    InputMaskModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    ClipboardModule,
    NgSelectModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    DividerModule,
    TabMenuModule,
    MatTabsModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  exports: [
    PaymentMethodsParcelamentoComponent,
    DialogPesquisaSastifacaoComponent,
    AcessoMeusParcelamentosComponent,
    SelecionarFornecimentoComponent,
    MeusParcelamentosMenuComponent,
    MeusParcelamentosMenuComponent,
    CartaoDadosPagamentoComponent,
    SolicitacaoConcluidaComponent,
    CartaoDadosPagamentoComponent,
    PerguntasFrequentesComponent,
    InputBuscaPrincipalComponent,
    InputSelectEnderecoComponent,
    PoliticaPrivacidadeComponent,
    NotificacaoProcessoComponent,
    ResponsavelProcessoComponent,
    SecurityCodeWarningComponent,
    SecurityCodeWarningComponent,
    DialogCancelamentoComponent,
    SelectFornecimentoComponent,
    RegisteredPasswordComponent,
    VerificacaoUsuarioComponent,
    ListaQuestionariosComponent,
    ModalExcluirCartaoComponent,
    ModalExcluirCartaoComponent,
    AgvEnderecoImovelComponent,
    ChartHistConsumoComponent,
    FormInformacoesIncorretas,
    CadastrarClienteComponent,
    SucessoNovaSenhaComponent,
    BoxCartoesSalvosComponent,
    InputDadosCartaoComponent,
    BoxCartoesSalvosComponent,
    InputDadosCartaoComponent,
    EnderecoCorrespComponent,
    PaginacaoTabelaComponent,
    PagamentoFaturaComponent,
    RedirecionaMapsComponent,
    TabelaHistoricoComponent,
    PaymentMethodsComponent,
    InputNovaSenhaComponent,
    AcessibilidadeComponent,
    ResumoProcessoComponent,
    EnderecoImovelComponent,
    InputFormAdminComponent,
    ChartIotDiarioComponent,
    AgvInputSelectComponent,
    UploadArquivoComponent,
    ConfirmaPagtoComponent,
    EtapaProcessoComponent,
    ComunicaPagtoComponent,
    InputTelefoneComponent,
    FiltroFaturasComponent,
    LoginMensagemComponent,
    TabelaTarifasComponent,
    AgvButtonEditComponent,
    ModalInfoDocComponent,
    AvisoCookiesComponent,
    ButtonSabespComponent,
    NoticiaListaComponent,
    FornecimentoComponent,
    ListaTarifasComponent,
    InputMtdoPagComponent,
    InputMtdoPagComponent,
    SignaturePadComponent,
    AgvButtonExcComponent,
    InputImagemComponent,
    LocalizacaoComponent,
    SlideToggleComponent,
    InputSelectComponent,
    MenuServicoComponent,
    VideoDialogComponent,
    TabelaAdminComponent,
    CadastroOcrComponent,
    InputBuscarComponent,
    ModalOpcoesComponent,
    HeaderAdminComponent,
    ParcelDebtsComponent,
    InputNumberComponent,
    AgvItemListComponent,
    AvisoTextoComponent,
    InputEmailComponent,
    ListaDadosComponent,
    ReactiveFormsModule,
    AGVStepperComponent,
    ListaCheckComponent,
    TabelaCardComponent,
    PaginationComponent,
    PagtoTableComponent,
    BreadcrumbComponent,
    CardFaturaComponent,
    PerfilCnpjComponent,
    IotChartJSComponent,
    ModalHomeComponent,
    ChartCnpjComponent,
    AgvDialogComponent,
    MenuAdminComponent,
    InputTextComponent,
    MatFormFieldModule,
    MatFormFieldModule,
    ValidaCpfComponent,
    AgvButtonComponent,
    InputDateComponent,
    InputFileComponent,
    AgvInputMultSelect,
    InputHoraComponent,
    TabelaIotComponent,
    EnderecoComponent,
    CheckboxComponent,
    PageSizeComponent,
    CarouselComponent,
    CardFaturaDesktop,
    InputCpfComponent,
    MensagemComponent,
    CheckoutComponent,
    CheckoutComponent,
    AgvTableComponent,
    SidenavComponent,
    HttpClientModule,
    NoticiaComponent,
    FlexLayoutModule,
    AgvIconComponent,
    FlexLayoutModule,
    SpinnerComponent,
    LoadingComponent,
    CardFaturaMobile,
    SidebarComponent,
    ChartJSComponent,
    NgxBarcodeModule,
    ChatbotComponent,
    HeaderComponent,
    SelectComponent,
    InputMaskModule,
    TabelaComponent,
    LayoutComponent,
    FooterComponent,
    MatButtonModule,
    LoaderComponent,
    LoaderComponent,
    MatTableModule,
    MatRadioModule,
    LoginComponent,
    ModalComponent,
    TimelineModule,
    PopUpComponent,
    CardInfoFornec,
    CardSemFornec,
    EllipsisPipe,
    TabComponent,
    IotComponent,
    FormsModule,
    SafePipe,
  ],
  providers: [
    GetStyleEnderecoComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    GetSelectFielStyle,
    ListaTipoCivico,
    EllipsisPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class SharedModule {}
