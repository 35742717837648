<div class="table-container">
  <div class="info-holder">
    <div class="mes-atual">Mês atual</div>
    <div class="mes-anterior">Mês anterior</div>
    <div></div>
  </div>

  <table mat-table [dataSource]="getListaItensPaginados()" matSort>
    <!-- Data atual Column -->
    <ng-container matColumnDef="mesAtualData">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let element">
        <div>
          {{element.mesAtualData | date: 'dd MMM yyyy'}}
        </div>
        <div class="desktopHidden">
          {{element.mesComparativoData | date: 'dd MMM yyyy'}}
        </div>
    </td>
    </ng-container>

    <!-- Consumo atual Column -->
    <ng-container matColumnDef="mesAtualConsumo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumo</th>
      <td mat-cell *matCellDef="let element" class="mobileTd">
        <div>
          {{element.mesAtualConsumo ?? 0 | number: '0.1-2'}} m³
        </div>
        <div class="desktopHidden">
          {{element.mesAtualConsumo ?? 0 | number: '0.1-2'}} m³
        </div>
      </td>
    </ng-container>

    <!-- Data anterior Column -->
    <ng-container matColumnDef="mesComparativoData" class="mobileHidden">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell class="grayCell" *matCellDef="let element">{{element.mesComparativoData | date: 'dd MMM yyyy'}}</td>
    </ng-container>

    <!-- Consumo anterior Column -->
    <ng-container matColumnDef="mesComparativoConsumo" class="mobileHidden">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumo</th>
      <td mat-cell class="grayCell" *matCellDef="let element">{{element.mesComparativoConsumo ?? 0 | number: '0.1-2'}} m³</td>
    </ng-container>

    <!-- Comparativo Column -->
    <ng-container matColumnDef="comparativo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comparativo</th>
      <td mat-cell *matCellDef="let element">
        <span class="cardItem" [ngClass]="{'green': element.comparativo < 0, 'red': element.comparativo >= 0}">
          <img src="../../../../../../assets/imgs/png/icn-arrow-down.png" *ngIf="element.comparativo < 0" [name]="'icn_arrow_down'">
          <img src="../../../../../../assets/imgs/png/icn-arrow-up.png" *ngIf="element.comparativo >= 0" [name]="'icn_arrow_up'">
          {{ element.comparativo ?? 0 | number : '0.1-2' }} %
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <agv-paginacao-tabela
      class="pagination"
      [itensPorPagina]="itensPorPagina"
      [totalItens]="dataSource.data.length"
      (paginaAtualEvento)="atualizarPaginaAtual($event)"
  ></agv-paginacao-tabela>
</div>
