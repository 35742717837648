import {
  SimpleChanges,
  HostListener,
  EventEmitter,
  ElementRef,
  Component,
  OnChanges,
  OnDestroy,
  ViewChild,
  Renderer2,
  Output,
  OnInit,
  Input,
} from '@angular/core';
import { ConsumoMensal } from 'src/app/core/models/iotModels';
import { Fatura } from 'src/app/core/models/faturaCompleta';

// Importes registres
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';
import { Regex } from '../../utils/regex';

Chart.register(ChartDataLabels);

@Component({
  selector: 'iot-chart-js',
  templateUrl: './iot-chart-js.component.html',
  styleUrls: ['./iot-chart-js.component.scss'],
})
export class IotChartJSComponent implements OnInit, OnChanges, OnDestroy {
  // String
  eleRefId: string;

  // Actions
  @Output() monthSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitValueDate = new EventEmitter<string>();
  @Output() pointClicked = new EventEmitter<void>();

  // Views
  @ViewChild('containerBody', { static: true })
  containerBody: ElementRef<HTMLDivElement>;
  @ViewChild('charts', { static: true }) charts: ElementRef<HTMLDivElement>;
  @ViewChild('chevronRight') chevronRight: ElementRef;
  @ViewChild('chevronLeft') chevronLeft: ElementRef;

  // Input Lista
  @Input() consumoMensal: ConsumoMensal[] = [];
  @Input() faturas: Fatura[] = [];

  // Lista
  months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  coordenates: {
    y: string;
    x: string;
    z: number;
    consumo: string;
    previsao: string;
  }[] = [];

  // Number
  clickedIndex: number = -1;

  // Boolean
  chartInitialized: boolean = false;

  // Others
  chipConsumo: any;
  chart: any;

  // Event Html click
  @HostListener('click', ['$event']) onClick(e: any) {
    e.stopPropagation();
    this.carregaCard(e);
  }

  // Event Html keypress
  @HostListener('keypress', ['$event']) onKeyPress(e: any) {
    var key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.carregaCard(e);
    }
  }

  // Event Html responsividade
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.verificaTamanhoTela();
  }

  // Render usado para montar a tela com o scope
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initializeChart();

    setTimeout(() => {
      const storedMonth = sessionStorage.getItem('selectedMonth');
      const currentMonth = storedMonth || this.getCurrentMonth();
      this.onMonthSelect(currentMonth);
      this.highlightCurrentMonthInChart();
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.chart) this.chart.destroy();
  }

  onMonthSelect(month: string): void {
    sessionStorage.setItem('selectedMonth', month);
    this.monthSelected.emit(month);
  }

  highlightCurrentMonthInChart() {
    const selectedMonth = sessionStorage.getItem('selectedMonth'); // Verifica se há um mês armazenado
    const currentMonthIndex = selectedMonth
      ? this.months.indexOf(selectedMonth)
      : new Date().getMonth(); // Índice do mês atual ou armazenado

    this.clickedIndex = currentMonthIndex; // Define o índice do mês selecionado
    if (this.chart) {
      this.chart.update(); // Atualiza o gráfico para refletir a seleção
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }

    if (changes['faturas']?.currentValue?.length > 0) {
      this.faturas = changes['faturas']?.currentValue;
      this.configuraFatura(); // Configura e cria o gráfico de forma segura
    }

    if (changes['consumoMensal']) {
      this.configuraFatura();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.chart) {
        this.initializeChart(); // Inicializa o gráfico após a view estar pronta
      }
    }, 0); // Reduza o atraso para o mínimo necessário
  }

  // Obtenha o nome do mês com a primeira letra em maiúsculo
  getCurrentMonth(): string {
    const currentMonth = new Date().toLocaleString('default', {
      month: 'long',
    });
    return Regex.capitalize(currentMonth);
  }

  verificaTamanhoTela() {
    if (this.chart) this.initializeChart();
  }

  configuraFatura() {
    this.coordenates = this.consumoMensal.map((item, index) => {
      const yValue = parseFloat(
        `${
          item.consumo != null
            ? item.consumo
            : item.previsao != null
            ? item.previsao
            : 0
        }`
      ).toFixed(2);

      return {
        y: yValue,
        x: this.months[index],
        z: item.variacao ?? 0,
        consumo: parseFloat(
          `${item.consumo != null ? item.consumo : 0}`
        ).toFixed(2),
        previsao: parseFloat(
          `${item.previsao != null ? item.previsao : 0}`
        ).toFixed(2),
      };
    });
    this.createChart(this);
  }

  initializeChart() {
    // Limpa a referência ao gráfico
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }

    // Cria o gráfico após a destruição do anterior
    setTimeout(() => {
      this.createChart(this);
    }, 0);
  }

  carregaCard(e: any) {
    if (e.srcElement.parentNode.classList.contains('chip-items')) {
      const elementId = e.srcElement.parentNode.id;
      this.eleRefId = elementId;
      const month = this.eleRefId.split('-').pop() as string;
      this.emitValueDate.emit(month);
    } else if (e.srcElement.classList.contains('chip-items')) {
      const elementId = e.srcElement.id;
      this.eleRefId = elementId;
      const month = this.eleRefId.split('-').pop() as string;
      this.emitValueDate.emit(month);
    }
  }

  mapPrevisaoIndices(coordenates: any[]): number[] {
    return coordenates
    .map((dataPoint, index) => {
        if (dataPoint.consumo == "0.00" && dataPoint.previsao != "0.00") {
          return index;
        }
        return -1;
      })
      .filter((index) => index !== -1);
  }

  createChart(scope: any) {
    const canvas = document.getElementById('chartFaturas') as HTMLCanvasElement;
    if (!canvas) {
      console.error('Canvas não encontrado');
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Contexto do canvas não disponível');
      return;
    }

    if (this.chart) {
      this.chart.destroy();
    }
    const criaConsumoTexto = (
      ctx: any,
      x: number,
      y: number,
      mockData: number[],
      index: number
    ) => {
      ctx.textAlign = 'center';
      ctx.font = '600 18px Satoshi';

      const offsetY = +12;
      const consumo = mockData[index];
      this.chipConsumo;
      ctx.fillText(`${consumo} m³`, x, y + offsetY - 24);
      return consumo; // Retorna o valor de consumo
    };

    const criarCardElement = (
      chart: any,
      datasetIndex: number,
      XPosition: number,
      YPosition: number,
      consumo: number
    ) => {
      const el = scope.renderer.createElement('a');
      const cardId = `card-${datasetIndex}`;
      const ariaLabel = generateAriaLabel(chart, datasetIndex);

      scope.renderer.setAttribute(el, 'id', cardId);
      scope.renderer.setAttribute(el, 'class', 'chip-items');
      scope.renderer.setAttribute(el, 'cardid', `card-${datasetIndex}`);
      scope.renderer.setAttribute(el, 'aria-label', ariaLabel);
      scope.renderer.setAttribute(el, 'role', 'button');
      scope.renderer.setAttribute(el, 'tabindex', '0');

      scope.renderer.setStyle(el, 'position', 'absolute');
      scope.renderer.setStyle(el, 'top', `${YPosition}px`);
      scope.renderer.setStyle(el, 'left', `${XPosition}px`);

      return el;
    };

    const criarStatusElement = (index: number) => {
      const span = scope.renderer.createElement('span');
      scope.renderer.setAttribute(span, 'class', 'status cardItem');

      // Converte a variação para número
      const variacao = parseFloat(scope.coordenates[index].z).toFixed(2); 
      const textStatus = scope.renderer.createText(`${variacao}%`); 

      // Criar o elemento img
      const img = scope.renderer.createElement('img');
      const imgSrc =
        parseFloat(variacao) === 0
          ? '../../../../../../assets/imgs/png/icn_arrow_zero.png'
          : parseFloat(variacao) < 0 ||
            (index > 0 &&
              parseFloat(scope.coordenates[index - 1].consumo) >
                parseFloat(scope.coordenates[index].consumo))
          ? '../../../../../../assets/imgs/png/icn-arrow-down.png'
          : '../../../../../../assets/imgs/png/icn-arrow-up.png';

      scope.renderer.setAttribute(img, 'src', imgSrc);
      scope.renderer.setAttribute(img, 'width', '9');
      scope.renderer.setAttribute(img, 'height', '9');
      scope.renderer.setAttribute(img, 'alt', 'Arrow icon');

      // Anexar o img ao elemento principal
      scope.renderer.appendChild(span, img);

      // Adicionar a classe condicionalmente
      if (parseFloat(variacao) === 0) {
        scope.renderer.addClass(span, 'orange');
      } else if (
        parseFloat(variacao) < 0 ||
        (index > 0 &&
          parseFloat(scope.coordenates[index - 1].consumo) >
            parseFloat(scope.coordenates[index].consumo))
      ) {
        scope.renderer.addClass(span, 'green');
      } else {
        scope.renderer.addClass(span, 'red');
      }

      scope.renderer.appendChild(span, textStatus);
      return span;
    };

    const appendCardElement = (el: any) => {
      scope.renderer.appendChild(scope.charts.nativeElement, el);
    };

    const updateActiveElement = () => {
      if (scope.eleRefId) {
        document.getElementById(`${scope.eleRefId}`)?.classList.add('active');
      }
    };

    const generateAriaLabel = (chart: any, datasetIndex: number) => {
      const faturas = scope.faturas;
      const findData = faturas[datasetIndex];

      if (findData) {
        const { mes, situacaoDaFatura, valor, consumo } = findData;
        const formattedValor = valor.toString().replace(/\./g, ',');
        return `Consumo no mês de ${mes}, Status ${situacaoDaFatura}, Valor R$ ${formattedValor}, Consumo ${consumo} metros cúbicos.`;
      }

      return '';
    };

    const highLightBar = {
      id: 'highLightBar',
      beforeDraw(chart: any) {
        const {
          ctx,
          chartArea: { bottom, top },
        } = chart;
        const changeColor = (color: string) => {
          return getComputedStyle(chart.canvas).getPropertyValue(color);
        };
        const lineColor = changeColor('--color-primary-border-td');

        // Verificação de segurança
        if (
          scope.clickedIndex !== null &&
          chart.getDatasetMeta(0).data[scope.clickedIndex]
        ) {
          const XPosition = chart.getDatasetMeta(0).data[scope.clickedIndex].x;
          const YPosition = bottom + 58;
          const barWidth = 80;
          const barHeight = chart.height - 10;
          const cornerRadius = 10;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(
            XPosition - barWidth / 2 + cornerRadius,
            YPosition - barHeight
          );
          ctx.lineTo(
            XPosition + barWidth / 2 - cornerRadius,
            YPosition - barHeight
          );
          ctx.arcTo(
            XPosition + barWidth / 2,
            YPosition - barHeight,
            XPosition + barWidth / 2,
            YPosition - barHeight + cornerRadius,
            cornerRadius
          );
          ctx.lineTo(XPosition + barWidth / 2, YPosition - cornerRadius);
          ctx.arcTo(
            XPosition + barWidth / 2,
            YPosition,
            XPosition + barWidth / 2 - cornerRadius,
            YPosition,
            cornerRadius
          );
          ctx.lineTo(XPosition - barWidth / 2 + cornerRadius, YPosition);
          ctx.arcTo(
            XPosition - barWidth / 2,
            YPosition,
            XPosition - barWidth / 2,
            YPosition - cornerRadius,
            cornerRadius
          );
          ctx.lineTo(
            XPosition - barWidth / 2,
            YPosition - barHeight + cornerRadius
          );
          ctx.arcTo(
            XPosition - barWidth / 2,
            YPosition - barHeight,
            XPosition - barWidth / 2 + cornerRadius,
            YPosition - barHeight,
            cornerRadius
          );
          ctx.closePath();
          ctx.fillStyle = lineColor;
          ctx.fill();
          ctx.restore();
        }
      },
    };

    const floatingInfoBottom = {
      id: 'floatingInfoBottom',
      afterDatasetsDraw(chart: any) {
        const {
          ctx,
          chartArea: { bottom },
        } = chart;
        const mockData = chart.config.data.datasets[0].data.map(
          (d: any) => d.y
        ); // Extrai os dados mockados do gráfico
        for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
          const XPosition = chart.getDatasetMeta(0).data[i].x;
          const YPosition = bottom;
          ctx.save();
          ctx.restore();
        }
      },
      afterDraw(chart: any) {
        const {
          ctx,
          chartArea: { bottom },
        } = chart;
        const months = chart.config.data.labels; // Obter os meses das labels do gráfico
        for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
          const XPosition = chart.getDatasetMeta(0).data[i].x;
          const YPosition = bottom;
          const mes = months[i]; // Obter o mês correspondente ao índice

          ctx.save();
          ctx.textAlign = 'center';
          ctx.font = '600 14px Satoshi';
          ctx.fillText(mes, XPosition, YPosition - 18); // Desenhar o texto do mês no gráfico
          ctx.restore();
        }
      },
      beforeDraw(chart: any) {
        const changeColor = (color: string) => {
          return getComputedStyle(chart.canvas).getPropertyValue(color);
        };

        const {
          ctx,
          chartArea: { bottom },
        } = chart;
        // Linha tracejado vertical
        const lineColor = changeColor('--color-vertical-chart-line');
        const lineWidth = 1;
        const lineOffset = 84;
        const lineLength = 100;
        const dashPattern = [3, 3];

        for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
          const XPosition = chart.getDatasetMeta(0).data[i].x;
          const YPosition = bottom - 54;

          ctx.save();
          ctx.beginPath();
          ctx.setLineDash(dashPattern);
          ctx.moveTo(XPosition, YPosition);
          ctx.lineTo(XPosition, YPosition - (lineOffset + lineLength));
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = lineColor;
          ctx.stroke();
          ctx.restore();
        }
      },
      afterBuildTicks(chart: any) {
        let elements: any = document.querySelectorAll('.chip-items');
        elements.forEach((element: any) => {
          element.remove();
        });
        chart.canvas.parentNode.style.height = '320px';
      },
    };

    const floatingLabelsBottom = {
      id: 'floatingLabelsBottom',
      afterDraw(chart: any) {
        const {
          ctx,
          chartArea: { bottom },
        } = chart;
        const cardItems = document.querySelectorAll('.chip-items');
        cardItems.forEach((element: any) => {
          element.remove();
        });

        for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
          const XPosition = chart.getDatasetMeta(0).data[i].x;
          const YPosition = bottom + 25;

          const data = chart.config.data.datasets[0].data.map(
            (d: any) => d.y
          ); // Extrai os dados mockados do gráfico

          const consumo = criaConsumoTexto(
            ctx,
            XPosition,
            YPosition,
            data,
            i
          );
          const el = criarCardElement(chart, i, XPosition, YPosition, consumo);
          const span = criarStatusElement(i); // Passa o índice para obter a variacao correta
          scope.renderer.appendChild(el, span);
          appendCardElement(el);
        }
        updateActiveElement();
      },
    };

    const previsaoIndices = this.mapPrevisaoIndices(this.coordenates);

    this.chart = new Chart('chartFaturas', {
      type: 'line',
      plugins: [
        highLightBar,
        ChartDataLabels,
        floatingInfoBottom,
        floatingLabelsBottom,
      ],
      data: {
        labels: this.months, // Adiciona os meses como labels do gráfico
        datasets: [
          {
            pointRadius: 9,
            pointHoverRadius: 12,
            pointHoverBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverBorderWidth: 8,
            borderWidth: 2,
            pointBackgroundColor: '#fff',
            fill: false,
            label: 'Consumo',
            data: this.coordenates,
            backgroundColor: '#66c3e8',
            borderDash: () => {
              return [5, 4];
            },
            pointStyle: 'circle',
            tension: 1,
            cubicInterpolationMode: 'monotone',
            order: 1,
            datalabels: {
              display: false,
            },
            pointBorderColor: (ctx: any) => {
              return previsaoIndices.includes(ctx.dataIndex)
                ? '#cacaca'
                : '#66c3e8';
            },
            segment: {
              borderDash: (ctx) => {
                const { p0DataIndex, p1DataIndex } = ctx;
                const dataPoint = this.coordenates[p1DataIndex];
                return previsaoIndices.includes(p1DataIndex)
                  ? [5, 5]
                  : [];
              },
              borderColor: (ctx) => {
                const { p0DataIndex, p1DataIndex } = ctx;
                const dataPoint = this.coordenates[p1DataIndex];
                return previsaoIndices.includes(p1DataIndex)
                ? '#cacaca'
                : '#66c3e8';
              },
            },
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grace: 0,
            offset: true,
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              z: 1,
              display: false,
            },
          },
          y: {
            min: 0.5,
            beginAtZero: true,
            grace: '200%',
            stacked: true,
            border: {
              display: false,
            },
            ticks: {
              display: false,
              z: 1,
            },
            grid: {
              display: false,
              drawTicks: false,
            },
          },
        },
        layout: {
          padding: {
            top: 0,
            bottom: 52,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            opacity: 1,
            color: '#fff',
          },
          tooltip: {
            enabled: false,
            mode: 'index',
            intersect: false,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        onClick: (e, elements) => {
          const points = this.chart.getElementsAtEventForMode(
            e,
            'nearest',
            { intersect: false },
            true
          );
          if (points.length) {
            const firstPoint = points[0];
            const label =
              this.chart.config.data.datasets[0].data[firstPoint.index].x;
            this.clickedIndex = firstPoint.index;
            this.chart.update();
            this.onMonthSelect(label);
          }
        },
      },
    });
  }
}
