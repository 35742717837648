import { BuscaFaturasPorFornecimentoResumido } from 'src/app/core/models/service/faturasPorFornecimento-resumido';
import { DownloadZip } from '../core/models/reqBody/faturas/req-download-zip';
import { TokenStorageService } from './tokenStorageServices.service';
import { environment } from '../../environments/environment';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface UrlFatura {
  url64: string;
}

export class DetalheFatura {
  // String
  estadoSaldoPagamento: string;
  codigoPagamento: string;
  dataVencimento: string;
  documentoId: string;
  dataEmissao: string;
  
  // Number
  numeroParcela: number;
  valor: number;

  constructor(
    codigoPagamento?: string,
    documentoId?: string,
    numeroParcela?: number,
    dataEmissao?: string,
    valor?: number,
    dataVencimento?: string,
    estadoSaldoPagamento?: string
  ) {
    this.codigoPagamento = codigoPagamento ? codigoPagamento : '';
    this.documentoId = documentoId ? documentoId : '';
    this.numeroParcela = numeroParcela ? numeroParcela : 0;
    this.dataEmissao = dataEmissao ? dataEmissao : '';
    this.valor = valor ? valor : 0;
    this.dataVencimento = dataVencimento ? dataVencimento : '';
    this.estadoSaldoPagamento = estadoSaldoPagamento
      ? estadoSaldoPagamento
      : '';
  }
}
@Injectable({
  providedIn: 'root',
})
export class FaturaServices {
  private readonly BASE_URL = `${environment.uri}`;
  private tokenService = inject(TokenStorageService);

  constructor(private http: HttpClient) {}

  listarFaturasPorFornecimento(
    fornecimento: string
  ): Observable<BuscaFaturasPorFornecimentoResumido> {
    return this.http.get<BuscaFaturasPorFornecimentoResumido>(
      `${this.BASE_URL}v1/simplificada/fatura/fornecimento/${fornecimento}`
    );
  }

  getUrlFatura(fornecimento: string, codFatura: string) {
    return this.http.get<UrlFatura>(
      `${this.BASE_URL}v1/fastdata/simplificada/fornecimento/${fornecimento}/fatura/${codFatura}/download`
    );
  }

  getUrlFaturaCompleta(
    fornecimento: string,
    codFatura: string,
    authToken?: string
  ) {
    return this.http.get<UrlFatura>(
      `${this.BASE_URL}v1/completa/fornecimento/${fornecimento}/fatura/${codFatura}/download`,
      {
        headers: {
          Authorization: `Bearer ${authToken ?? this.tokenService.getToken()}`,
        },
      }
    );
  }

  getUrlFaturaCompletaZipV2(zip: DownloadZip) {
    return this.http.post<UrlFatura>(
      `${this.BASE_URL}v2/completa/fornecimento/codigopagamento/zip`,
      zip,
      {
        headers: {
          Authorization: `Bearer ${this.tokenService.getToken()}`,
        },
      }
    );
  }

  listarDetalhesFatura(
    documentoId: string,
    numeroParcela: string,
    authToken: string
  ) {
    return this.http.get<DetalheFatura>(
      `${this.BASE_URL}v1/completa/fatura/fornecimento/${documentoId}/cliente/listafaturas/detalhes/numeroparcela/${numeroParcela}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }
}
