import {
  SimpleChanges,
  EventEmitter,
  Component,
  OnChanges,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'agv-table',
  templateUrl: './agv-table.component.html',
  styleUrls: ['./agv-table.component.scss'],
})
export class AgvTableComponent implements OnInit, OnChanges {
  // Input Type
  @Input() colunas: {
    label: string;
    type:
      | 'ToggleLabel'
      | 'LabelToggle'
      | 'Currency'
      | 'Checkbox'
      | 'Edt&Del'
      | 'Toggle'
      | 'Button'
      | 'Delete'
      | 'Edit'
      | 'Text'
      | 'Date'
      | 'Img'
      | 'Int';
  }[];

  // Input Lista
  @Input() listLimitRow: number[];
  @Input() valores: any[];

  // Input String
  @Input() placeholderBusca: string;
  @Input() loadingDados: string;
  @Input() labelToggle: string;
  @Input() buttonText: string;
  @Input() dadosVazio: string;
  @Input() id: string;

  // Input Boolean
  @Input() showRowLimit: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() loading = false;


  // Number
  paginaSelecionada: number = 1;
  rowLimit: number;

  // String busca
  buscaLista: string = '';

  // Lista
  paginas: {
    pagina: {
      valor: number;
      show: boolean;
    };
    valores: any;
  }[] = [];
  indice: { index: string; type: string }[] = [];
  valoresFiltrados: any[];
  dados: any[];

  // Actions
  @Output() sendToggle = new EventEmitter<any>();
  @Output() sendEdit = new EventEmitter<any>();
  @Output() sendExc = new EventEmitter<any>();
  @Output() sendBtn = new EventEmitter<any>();

  constructor(private curreny: CurrencyPipe, private date: DatePipe) {}

  // Função executada ao iniciar component
  ngOnInit(): void {
    this.placeholderBusca === undefined || this.placeholderBusca === ''
      ? (this.placeholderBusca = 'Busca')
      : '';
    this.id === undefined || this.id === '' ? (this.id = 'default') : '';
    this.listLimitRow === undefined
      ? (this.listLimitRow = [5, 10, 20, 30, 40, 50])
      : undefined;
    this.rowLimit = this.showRowLimit
      ? this.listLimitRow[0]
      : this.valores.length;
  }

  // Função executada ao alterar valores no @Input
  ngOnChanges(changes: SimpleChanges): void {
    if (this.valores !== undefined && this.valores.length > 0) {
      this.valoresFiltrados = this.valores;
      this.indice = [];
      let col = 0;
      let lista = JSON.stringify(this.valores[0])
        .replaceAll('{', '')
        .replaceAll('}', '')
        .split('","');
      lista.forEach((i) => {
        if (
          this.colunas[col].type === 'Edit' ||
          this.colunas[col].type === 'Delete' ||
          this.colunas[col].type === 'Edt&Del' ||
          this.colunas[col].type === 'Button' ||
          this.colunas[col].type === 'Checkbox'
        ) {
          this.indice.push({ index: '', type: this.colunas[col].type });
        } else {
          this.indice.push({
            index: i.slice(0, i.indexOf(':')).replaceAll('"', ''),
            type: this.colunas[col].type,
          });
        }
        col++;
      });

      this.identificaPaginas();
    }
  }

  // Função para filtrar a lista com a descrição
  filtraListaBusca() {
    this.valoresFiltrados = [];
    if (this.buscaLista !== '') {
      this.valores.forEach((v) => {
        let col = 0;
        this.indice.forEach((i) => {
          if (
            i.index !== '' &&
            this.colunas[col].type !== 'ToggleLabel' &&
            this.colunas[col].type !== 'LabelToggle' &&
            this.colunas[col].type !== 'Checkbox' &&
            this.colunas[col].type !== 'Edt&Del' &&
            this.colunas[col].type !== 'Delete' &&
            this.colunas[col].type !== 'Button' &&
            this.colunas[col].type !== 'Toggle' &&
            this.colunas[col].type !== 'Edit'
          ) {
            if (v[i.index].toLowerCase().includes(this.buscaLista.toLowerCase())) {
              this.valoresFiltrados.push(v);
            }
          }
          col++;
        });
      });
    } else {
      this.valoresFiltrados = this.valores;
    }
    this.identificaPaginas();
  }

  // Emite a ação de click do botão
  btnClick(item: any) {
    this.sendBtn.emit(item);
  }
  // Emite a ação de click do botão editar
  editClick(item: any) {
    this.sendEdit.emit(item);
  }
  // Emite a ação de click do botão excluir
  deleteClick(item: any) {
    this.sendExc.emit(item);
  }

  // Emite a ação de click no toggle
  toggleClick(item: any) {
    this.sendToggle.emit(item);
  }

  // Função para aplicar formatação de real
  formataCurrency(item: any): string {
    if (
      item !== undefined &&
      item !== 'undefined' &&
      item !== null &&
      item !== 'null' &&
      item !== ''
    ) {
      return `${this.curreny.transform(item, 'BRL')}`;
    }
    return '---';
  }

  // Função para aplicar formatação em data
  formataDate(item: any): string {
    if (
      item !== undefined &&
      item !== 'undefined' &&
      item !== null &&
      item !== 'null' &&
      item !== '' &&
      item !== new Date()
    ) {
      return `${this.date.transform(
        new Date(
          item.split('-')[2],
          item.split('-')[1] - 1,
          item.split('-')[0]
        ),
        'dd/MM/yyyy'
      )}`;
    }
    return '---';
  }
  // Função para selecionar o limite de dados por página
  selectRowLimit(num: number) {
    this.rowLimit = num;
    this.identificaPaginas();
  }

  // Função para identificar a quantidade de página
  identificaPaginas() {
    if (this.valoresFiltrados.length > 0) {
      let paginas = Math.ceil(this.valoresFiltrados.length / this.rowLimit);
      this.paginas = [];
      for (let i = 0; i < paginas; i++) {
        let lista: any[] = [];
        for (let a = this.rowLimit * i; a < this.rowLimit * (i + 1); a++) {
          if (this.valoresFiltrados.length > a) {
            lista.push(this.valoresFiltrados[a]);
          }
        }
        this.paginas.push({
          pagina: { valor: i + 1, show: i + 1 <= 3 },
          valores: lista,
        });
      }
      this.dados = this.paginas[0].valores;
    } else {
      this.dados = [];
    }
  }

  // Função para ir para a primeira página
  primeiraPagina() {
    this.paginaSelecionada = 1;
    this.rePage();
    this.dados = this.paginas[this.paginaSelecionada - 1].valores;
  }
  // Função para voltar uma página
  voltarPagina() {
    this.paginaSelecionada > 1
      ? this.paginaSelecionada--
      : (this.paginaSelecionada = 1);
    this.rePage();
    this.dados = this.paginas[this.paginaSelecionada - 1].valores;
  }

  // Função para selecionar a página
  selecionaPagina(pagina: number) {
    this.paginaSelecionada = pagina;
    this.rePage();
    this.dados = this.paginas[this.paginaSelecionada - 1].valores;
  }

  // Função para avançar uma página
  proximaPagina() {
    if (this.paginaSelecionada < this.paginas.length) {
      this.paginaSelecionada++;
      this.rePage();
      this.dados = this.paginas[this.paginaSelecionada - 1].valores;
    }
  }
  // Função para ir para a última página
  ultimaPagina() {
    this.paginaSelecionada = this.paginas.length;
    this.rePage();
    this.dados = this.paginas[this.paginaSelecionada - 1].valores;
  }

  // Função para refazer paginação
  rePage() {
    this.paginas.forEach((p) => {
      if (this.paginaSelecionada === 1 && p.pagina.valor <= 3) {
        p.pagina.show = true;
      } else if (
        this.paginaSelecionada === this.paginas.length &&
        p.pagina.valor >= this.paginas.length - 2
      ) {
        p.pagina.show = true;
      } else if (
        this.paginaSelecionada + 2 > p.pagina.valor &&
        this.paginaSelecionada - 1 <= p.pagina.valor
      ) {
        p.pagina.show = true;
      } else {
        p.pagina.show = false;
      }
    });
  }
}
