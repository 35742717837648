import { Cnpj } from "../service/cadastroCliente";

export class Toponimo {
  toponimoId: string;
  toponimoCodigo: string;
  toponimoDescr: string;
  id?: string;
  cod?: string;
  desc?: string;

  constructor() {
    this.toponimoId = '';
    this.toponimoCodigo = '';
    this.toponimoDescr = '';
  }
}

export class EnderecoBuscaPla {
  logradouroId:string;
  idLogradouro: string;
  logradouro: string;
  idBairro: string;
  bairro: string;

  constructor() {
    this.logradouroId = '';
    this.idLogradouro = '';
    this.logradouro = '';
    this.idBairro = '';
    this.bairro = '';
  }
}

export class Tipocivico {
  tipoCivicoId: string;
  tipoCivicoCodigo: string;
  tipoCivicoDescr: string;
  constructor() {
    this.tipoCivicoId = '';
    this.tipoCivicoCodigo = '';
    this.tipoCivicoDescr = '';
  }
}

interface ITipocivico extends Tipocivico { }
interface IToponimo extends Toponimo { }

export class Endereco implements ITipocivico, IToponimo {
  // String
  [key: string]: any;
  codigoViario: string;
  cepId: string;
  cepCodigo: string;
  paisId: string;
  paisCodigo: string;
  paisDescr: string;
  regiaoId: string;
  regiaoCodigo: string;
  regiaoDescr: string;
  estadoId: string;
  estadoCodigo: string;
  estadoDesc: string;
  estadoDescr: string;
  municipioId: string;
  codigoMunicipio: string;
  municipioCodigo: string;
  municipioDescr: string;
  municipioBase: string;
  bairroId: string;
  bairroCodigo: string;
  bairroDescr: string;
  logradouroId: string;
  logradouroDescr: string;
  toponimoId: string;
  toponimoCodigo: string;
  toponimoDescr: string;
  toponimoCode: string;

  tipoCivicoId: string;
  tipoCivicoCodigo: string;
  tipoCivicoDescr: string;
  complemento: string;
  //para funcionar o que esta em hml e dev
  civico?: any;
  civicoId?: string;

  // Boolean
  cepBlock?: boolean;
  ufBlock?: boolean;
  municipioBlock?: boolean;
  bairroBlock?: boolean;
  toponimoBlock?: boolean;
  logradouroBlock?: boolean;
  tipoCivicoBlock?: boolean;
  numeroBlock?: boolean;
  complementoBlock?: boolean;
  block?: boolean;

  constructor() {
    this.codigoViario = '';
    this.cepId = '';
    this.cepCodigo = '';
    this.paisId = '';
    this.paisCodigo = '';
    this.paisDescr = '';
    this.regiaoId = '';
    this.regiaoCodigo = '';
    this.regiaoDescr = '';
    this.estadoId = '';
    this.estadoCodigo = '';
    this.estadoDesc = '';
    this.estadoDescr = '';
    this.municipioId = '';
    this.codigoMunicipio = '';
    this.municipioCodigo = '';
    this.municipioDescr = '';
    this.municipioBase = '';
    this.bairroId = '';
    this.bairroCodigo = '';
    this.bairroDescr = '';
    this.toponimoId = '';
    this.toponimoDescr = '';
    this.logradouroId = '';
    this.toponimoCodigo ='';
    this.toponimoCode='';
    this.logradouroDescr = '';
    this.tipoCivicoCodigo = '';
    this.tipoCivicoId = '';
    this.complemento = '';
    this.cepBlock = false;
    this.block = false;
    this.civicoId = '';
  }

  public convertEnd(
    codigoViario: string,
    cepId: string,
    cepCodigo: string,
    paisId: string,
    paisCodigo: string,
    paisDescr: string,
    regiaoId: string,
    regiaoCodigo: string,
    regiaoDescr: string,
    estadoId: string,
    estadoCodigo: string,
    estadoDesc: string,
    estadoDescr: string,
    municipioId: string,
    codigoMunicipio: string,
    municipioCodigo: string,
    municipioDescr: string,
    municipioBase: string,
    bairroId: string,
    bairroCodigo: string,
    bairroDescr: string,
    toponimoId: string,
    toponimoCodigo: string,
    toponimoDescr: string,
    logradouroId: string,
    logradouroDescr: string,
    tipoCivicoId: string,
    tipoCivicoCodigo: string,
    tipoCivicoDescr: string,
    complemento: string
  ) {
    this.codigoViario = codigoViario;
    this.cepId = cepId;
    this.cepCodigo = cepCodigo;
    this.paisId = paisId;
    this.paisCodigo = paisCodigo;
    this.paisDescr = paisDescr;
    this.regiaoId = regiaoId;
    this.regiaoCodigo = regiaoCodigo;
    this.regiaoDescr = regiaoDescr;
    this.estadoId = estadoId;
    this.estadoCodigo = estadoCodigo;
    this.estadoDesc = estadoDesc;
    this.estadoDescr = estadoDescr;
    this.municipioId = municipioId;
    this.codigoMunicipio = codigoMunicipio;
    this.municipioCodigo = municipioCodigo;
    this.municipioDescr = municipioDescr;
    this.municipioBase = municipioBase;
    this.bairroId = bairroId;
    this.bairroCodigo = bairroCodigo;
    this.bairroDescr = bairroDescr;
    this.toponimoId = toponimoId;
    this.toponimoCodigo = toponimoCodigo;
    this.toponimoDescr = toponimoDescr;
    this.logradouroId = logradouroId;
    this.logradouroDescr = logradouroDescr;
    this.tipoCivicoCodigo = tipoCivicoCodigo;
    this.tipoCivicoId = tipoCivicoId;
    this.tipoCivicoDescr = tipoCivicoDescr;
    this.complemento = complemento;
  }
}

export interface Municipio {
  id: string,
  codigo: string,
  descricao: string
}

