import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from '../../observables/theme.service';

@Component({
  selector: 'agv-paginacao-tabela',
  templateUrl: './paginacao-tabela.component.html',
  styleUrls: ['./paginacao-tabela.component.scss']
})
export class PaginacaoTabelaComponent implements OnInit, OnChanges {
  // Controlador do tema
  currentTheme: string;

  @Input() totalItens: number = 1;
  @Input() itensPorPagina: number = 1;
  @Input() next: boolean = false;


  @Input() paginaAtual: number = 1;
  public ultimaPagina: number = 1;

  isMobile: boolean = false;

  @Output() paginaAtualEvento = new EventEmitter<number>();

  constructor(
    private theme: ThemeService
  ) { }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 600);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itensPorPagina']?.currentValue !== changes['itensPorPagina']?.previousValue ||
      changes['paginaAtual']?.currentValue !== changes['paginaAtual']?.previousValue) {
      if (this.totalItens >= 1 && !this.next) {
        this.ultimaPagina = Math.ceil(this.totalItens / this.itensPorPagina);
        return
      } if(this.next) {
        this.ultimaPagina = this.paginaAtual++;
        return
      }
      this.atualizarPagina(this.paginaAtual);
    }
  }

  paginaAnterior() {
    if (this.paginaAtual > 1) {
      this.paginaAtual--;
      this.atualizarPagina(this.paginaAtual);
    }
  }

  proximaPagina() {
    if (this.paginaAtual < this.ultimaPagina) {
      this.paginaAtual++;
      this.atualizarPagina(this.paginaAtual);
    }
  }

  atualizarPagina(pagina: number): void {
    this.paginaAtual = pagina;
    this.paginaAtualEvento.emit(this.paginaAtual);
  }

  getPaginas(): number[] {
    const totalPaginas = Math.ceil(this.totalItens / this.itensPorPagina);
    return Array.from({ length: totalPaginas }, (_, index) => index + 1);
  }

}
