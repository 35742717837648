<div  class="dialog-error" tabindex="0" aria-hidden="true" >
  <div *ngIf="img" class="dialog-error__img">
    <agv-icon [name]="img" [ngClass]="{'loading': imgAnimation}" title="Ícone de exclamação"></agv-icon>
  </div>

    <div [ngStyle]="{'text-align': align}"  class="dialog-error__title" [innerHTML]="title"></div>

    <div [ngStyle]="{'text-align': align}"  class="dialog-error__content" [innerHTML]="content" ></div>

  <div class="dialog-error__button">
    <button mat-icon-button mat-dialog-close [mat-dialog-close]="true" class="button_close" aria-label="Fechar a caixa de dialogo" id="fechaPop" (keypress)="fechaPopUp($event)" >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
