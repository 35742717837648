import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'agv-input-imagem',
  template: `
    <div class="input-imagem" [ngClass]="{ error: error }">
      <div class="input-imagem__upload">
        <div class="input-imagem__upload__grupo">
          <label
            class="input-imagem__upload__grupo__label-principal"
            [for]="id + '__input-imagem__upload__grupo__input'"
            >{{ labelPrincipal }}</label
          >
          <a (click)="emitirClickAjuda(labelPrincipal)"
            ><agv-icon
              [name]="'icn-ajuda'"
              class="input-imagem__upload__grupo__icn"
              *ngIf="ativaAjuda"
            ></agv-icon
          ></a>
        </div>
        <div class="input-imagem__upload__grupo">
          <label
            [for]="id + '__input-imagem__upload__grupo__input'"
            class="input-imagem__upload__grupo__label-input"
            >{{ labelBotao }}</label
          >
          <input
            (change)="selecionaArquivo($event)"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            name=""
            [id]="id + '__input-imagem__upload__grupo__input'"
            class="input-imagem__upload__grupo__input"
          />
        </div>
      </div>
      <div class="input-imagem__img">
        <img
          class="input-imagem__img__cliente"
          *ngIf="documento"
          [src]="documento"
        />
        <agv-icon
          [name]="'icone_x'"
          (click)="limpaArquivo()"
          class=" input-imagem__img__fechaImagem"
          *ngIf="documento"
        ></agv-icon>
        <agv-icon
          *ngIf="!documento"
          [name]="'no-file'"
          class="input-imagem__img__icone"
        ></agv-icon>
      </div>
    </div>
    <div class="error-message" *ngIf="error">
      {{ errorMessage }}
    </div>
  `,
  styles: [
    `
      .input-imagem {
        border: 1px solid var(--color-gray-300);
        background-color: var(--color-white);
        flex-direction: row;
        padding: 18px 23px;
        border-radius: 4px;
        display: flex;
        height: 87px;
        width: 100%;

        &__upload {
          flex-direction: column;
          display: flex;
          width: 70%;

          &__grupo {
            flex-direction: row;
            margin-bottom: 7px;
            display: flex;
            gap: 24px;

            &__label-principal {
              color: var(--color-normal-grey);
              line-height: 1.25rem;
              font-size: 0.75rem;
              text-align: left;
              font-weight: 400;
              margin-top: -9px;
              display: flex;
              width: auto;
            }

            &__icn {
              cursor: pointer;
              display: flex;
              height: 18px;
              width: 18px;
            }

            &__label-input {
              border: 1px solid var(--color-gray-300);
              line-height: 1.125rem;
              text-align: center;
              font-size: 0.75rem;
              border-radius: 4px;
              padding: 2px 10px;
              min-width: 115px;
              font-weight: 400;
              cursor: pointer;
              display: flex;
            }

            &__input {
              display: none;
            }
          }
        }

        &__img {
          justify-content: end;
          align-items: center;
          display: flex;
          width: 30%;

          &__cliente {
            border-radius: 2px;
            height: 34px;
            width: 45px;
          }

          &__fechaImagem {
            margin-bottom: 34px;
            height: 14px;
            width: 16px;
          }
        }

        &.error {
          border: 1px solid var(--color-failed);
          .input-imagem {
            &__upload {
              &__grupo {
                &__label-principal,
                &__label-input {
                  color: var(--color-failed);
                }
              }
            }
          }
        }
      }
      .error-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 475px;
        color: var(--color-failed);
        font-size: 0.75rem;
        margin-top: 10px;
        text-align: left;
      }
    `,
  ],
})
export class InputImagemComponent implements OnInit, OnChanges {
  @Input() labelPrincipal: string;
  @Input() ativaAjuda: boolean;
  @Input() labelBotao: string;
  @Input() types: string[];
  @Input() id: string;
  @Input() file: any;

  errorMessage: string;
  documento: any;
  error: boolean;

  @Output() emitInfoSelecionada = new EventEmitter<string>();
  @Output() emitImgSelecionada = new EventEmitter<any>();
  @Output() emitImgLimpar = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    const reader = new FileReader();
    if (this.file) {
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.documento = reader.result as string;
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reader = new FileReader();
    if (this.file) {
      this.errorMessage = '';
      this.error = false;
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.documento = reader.result as string;
      };
    } else {
      this.documento = undefined;
    }
  }

  selecionaArquivo(event: any) {
    const arquivos = event.target.files;

    const reader = new FileReader();
    let arquivoSelecionado = arquivos[0];
    if (arquivoSelecionado) {
      if (arquivoSelecionado.size / 1024 / 1024 > 20) {
        this.errorMessage =
          'Arquivo possui mais de 10MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.';
        arquivoSelecionado = undefined;
        this.error = true;
      } else if (
        arquivoSelecionado.type !== 'image/jpg' &&
        arquivoSelecionado.type !== 'image/jpeg' &&
        arquivoSelecionado.type !== 'image/png'
      ) {
        this.errorMessage =
          'Arquivo possui mais de 10MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.';
        arquivoSelecionado = undefined;
        this.error = true;
      } else {
        reader.readAsDataURL(arquivoSelecionado);
        reader.onload = () => {
          this.documento = reader.result as string;
        };
      }

      this.emitImgSelecionada.emit(arquivoSelecionado);
    }
  }

  limpaArquivo() {
    this.emitImgLimpar.emit();
  }

  emitirClickAjuda(label: string) {
    this.emitInfoSelecionada.emit(label);
  }
}
