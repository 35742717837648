<div>
  <div class="chart-holder">
    <div class="info-holder">
      <div class="ano-fatura">
        <div class="custom-select-container">
          <label for="year-select">
            <h2>Faturas:</h2>
          </label>
          <div class="custom-select-wrapper">
            <span class="select-label">Ano:</span>
            <select id="year-select" class="custom-select" (change)="onYearChange($event)">
              <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="consumo-medio">
        <h2 aria-label="Consumo médio">Consumo médio</h2>
        <h4>Últimos 12 meses</h4>
        <h1>{{consumoAnualResponse.consumoMedio}} m³</h1>
      </div>
    </div>

    <iot-chart-js
      [faturas]="lstFaturas"
      [consumoMensal]="consumoAnualResponse.consumo"
      (monthSelected)="onMonthSelected($event)"
    ></iot-chart-js>
    <div class="divider"></div>
    <chart-iot-diario
      [consumoComparativoResponse]="consumoComparativoResponse"
      [selectedMonth]="selectedMonth"
      [selectedYear]="selectedYear"
      (getConsumoComparativoEvent)="getConsumoComparativo()"
    ></chart-iot-diario>
    <app-tabela-iot
      [consumoComparativoTableResponse]="consumoComparativoTableResponse"
    ></app-tabela-iot>
  </div>
</div>
