import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AnosIOTResponse, ConsumoAnualResponse, ConsumoComparativoResponse, ConsumoComparativoTableResponse } from '../core/models/iotModels';

@Injectable({
  providedIn: 'root',
})
export class iotServices {
  private readonly BASE_URL = `${environment.uri_iot}`;
  constructor(private http: HttpClient) {}

  getAnosLeituras(authToken: any, codePde: number) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<number[]>(
      `${this.BASE_URL}v1/iot/analise/consumo/anos/leituras/${codePde}`, { headers }
    );
  }

  getConsumoAnual(authToken: any, ano: number, codePde: number) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<ConsumoComparativoResponse[]>(
      `${this.BASE_URL}v1/iot/analise/consumo/anual/${ano}/${codePde}`, { headers }
    );
  }

  getConsumoComparativo(authToken: any, mes: number, ano: number, codePde: number, tipo : string) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<ConsumoComparativoResponse[]>(
      `${this.BASE_URL}v1/iot/analise/consumo/comparativo/${mes < 10 ? '0'+ mes: mes}/${ano}/${codePde}/${tipo}`, { headers }
    );
  }

  getConsumoComparativoTable(authToken: any, mes: number, ano: number, codePde: number, tipo : string) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<ConsumoComparativoTableResponse>(
      `${this.BASE_URL}v1/iot/analise/consumo/comparativo/detalhes/${mes < 10 ? '0'+ mes: mes}/${ano}/${codePde}/${tipo}`, { headers }
    );
  }
}
