import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConsumoComparativoTable, ConsumoComparativoTableResponse } from 'src/app/core/models/iotModels';

@Component({
  selector: 'app-tabela-iot',
  templateUrl: './tabela-iot.component.html',
  styleUrls: ['./tabela-iot.component.scss'],
})
export class TabelaIotComponent implements OnInit {
  @Input() consumoComparativoTableResponse: ConsumoComparativoTableResponse = {detalhes: []};

  displayedColumns: string[] = ['mesAtualData', 'mesAtualConsumo', 'mesComparativoData', 'mesComparativoConsumo', 'comparativo'];
  dataSource = new MatTableDataSource<ConsumoComparativoTable>(this.consumoComparativoTableResponse.detalhes);

  itensPorPagina: number = 10;
  paginaAtual: number = 1;


  @ViewChild(MatSort) sort: MatSort;


  pageSize: number = 5;
  paginatedData: ConsumoComparativoTable[] = [];
  currentTheme: string = 'light'; // Use 'theme-dark' para o tema escuro

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.paginateData(1);
  }

  atualizarPaginaAtual(pagina: number) {
    this.paginaAtual = pagina;
    this.paginateData(pagina);
  }

  paginateData(page: number) {
    const startIndex = (page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.consumoComparativoTableResponse.detalhes.slice(startIndex, endIndex);
  }

  onPageChange(page: number) {
    this.paginateData(page);
  }

  convertDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  getListaItensPaginados(): any[] {
    const indiceInicial = (this.paginaAtual - 1) * this.itensPorPagina;
    const indiceFinal = indiceInicial + this.itensPorPagina;
    return  this.dataSource.data.slice(indiceInicial, indiceFinal).length > 0 ? this.dataSource.data.slice(indiceInicial, indiceFinal): this.dataSource.data;
  }
}
