import {
  ConsumoComparativoTableResponse,
  ConsumoComparativoResponse,
  ConsumoComparativoTable,
  ConsumoAnualResponse,
  AnosIOTResponse,
} from 'src/app/core/models/iotModels';
import { ChartIotDiarioComponent } from 'src/app/shared/components/chart-iot-diario/chart-iot-diario.component';
import {
  SimpleChanges,
  ElementRef,
  OnChanges,
  Component,
  ViewChild,
  OnInit,
} from '@angular/core';
import { IotChartJSComponent } from 'src/app/shared/components/chart-iot/iot-chart-js.component';
import { FornecimentoService } from 'src/app/shared/observables/fornecimento.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { FaturaService } from 'src/app/shared/observables/fatura.service';
import { TabelaIotComponent } from '../tabela-iot/tabela-iot.component';
import { iotServices } from 'src/app/services/analiseIot.service';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { MatTableDataSource } from '@angular/material/table';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'iot-component',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.scss'],
})
export class IotComponent implements OnInit, OnChanges {
  // Views
  @ViewChild(ChartIotDiarioComponent) chartIotDiario: ChartIotDiarioComponent;
  @ViewChild(IotChartJSComponent) iotChart: IotChartJSComponent;
  @ViewChild(TabelaIotComponent) tabelaIot: TabelaIotComponent;

  // String
  mensagemErro: string = `Infelizmente, houve uma falha de comunicação com nosso servidor e não conseguimos carregar seus dados. Atualize a página ou tente novamente mais tarde.`;
  tituloNotificacao: string = 'Falha na comunicação';
  filtroSelecionado: string | null;
  step: string;

  // Boolean
  isRepresentanteLegal: boolean = false;
  estaCarregando: boolean = false;
  histConsumo: boolean = false;
  showInvoice: boolean = false;
  showError: boolean = false;

  // Lista
  columnstable: string[] = [
    'select',
    'dataEmissao',
    'valor',
    'dataVencimento',
    'situacaoDaFatura',
    'button',
  ];
  monthMapping: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  lstFaturas: Fatura[] = [];

  // Object
  anosIotResponse: AnosIOTResponse = { anos: [] };
  consumoAnualResponse: ConsumoAnualResponse = {
    consumo: [],
    consumoMedio: 0.0,
  };
  consumoComparativoResponse: ConsumoComparativoResponse; // Mudança para objeto
  consumoComparativoTableResponse: ConsumoComparativoTableResponse = {
    detalhes: [],
  };
  fornecimento: Fornecimento = new Fornecimento();

  // Outros
  @ViewChild('historicoFaturas', { read: ElementRef })
  destroyed$ = new Subject<boolean>();
  historicoFaturas: ElementRef;
  availableYears: number[] = [];
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number;

  constructor(
    private _storageToken: TokenStorageService,
    private fornObs: FornecimentoService,
    private iotServices: iotServices,
    private fatObs: FaturaService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);

    this.fornObs.currentFornecimento.subscribe((fornecimento) => {
      this.fornecimento = fornecimento;
    });

    this.fatObs.currentFaturas.subscribe((faturas) => {
      this.lstFaturas = [];
      if (faturas) {
        this.lstFaturas = faturas;
      } else {
        this.tituloNotificacao = 'Nada por aqui.';
        this.mensagemErro = `Este fornecimento ainda não possui nenhuma fatura fechada.<br/> Após o fechamento da fatura, ela aparecerá aqui.`;
      }
    });

    // Chama o método para buscar dados
    this.getAvailableyears();
    this.getConsumoAnual(new Date().getFullYear());
  }

  ngAfterViewInit() {
    if (this.chartIotDiario) {
      this.chartIotDiario.registerParentCallback(this.onTipoChange.bind(this));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  // Refazer as chamadas com o novo tipo selecionado
  onTipoChange(tipo: string) {
    this.getConsumoComparativo(tipo);
  }

  // Atualizar os dados do gráfico diário com base no mês selecionado
  onMonthSelected(month: string) {
    this.selectedMonth = this.monthMapping.indexOf(month) + 1;
    this.getConsumoComparativo();
  }

  onYearChange(event: Event) {
    this.selectedYear = +(event.target as HTMLSelectElement).value;
    this.getConsumoAnual(this.selectedYear);
  }

  getAvailableyears() {
    this.estaCarregando = true;
    this.showInvoice = true;

    let token = this._storageToken.getToken();
    this.iotServices
      .getAnosLeituras(token, this.fornecimento.codigoPde!)
      .subscribe({
        next: (data: any) => {
          data.anos.map((anos: number) => {
            this.availableYears.push(anos);
          });
          this.selectedMonth = new Date().getMonth() + 1;
        },
        error: () => {},
        complete: () => {},
      });
    this.estaCarregando = false;
  }

  getConsumoAnual(ano: number) {
    let token = this._storageToken.getToken();
    this.iotServices
      .getConsumoAnual(token, ano, this.fornecimento.codigoPde)
      .subscribe({
        next: (data: any) => {
          this.consumoAnualResponse.consumo = data.consumo;
          this.consumoAnualResponse.consumoMedio = data.consumoMedio;
        },
        error: () => {},
        complete: () => {},
      });
  }

  getConsumoComparativo(tipo: string = 'mes') {
    if (tipo === undefined || tipo === null || tipo === '') {
      tipo = 'mes';
    }
    this.estaCarregando = true;
    this.showInvoice = true;

    let token = this._storageToken.getToken();

    this.iotServices
      .getConsumoComparativo(
        token,
        this.selectedMonth ?? 1,
        this.selectedYear,
        this.fornecimento.codigoPde!,
        tipo
      )
      .subscribe({
        next: (data: any) => {
          this.consumoComparativoResponse = data;
          this.chartIotDiario.updateChart();
        },
        error: () => {},
        complete: () => {},
      });
    this.estaCarregando = false;
    this.getConsumoComparativoTable(tipo);
  }

  getConsumoComparativoTable(tipo: string = 'mes') {
    this.estaCarregando = true;
    this.showInvoice = true;

    let token = this._storageToken.getToken();

    this.iotServices
      .getConsumoComparativoTable(
        token,
        this.selectedMonth ?? 1,
        this.selectedYear,
        this.fornecimento.codigoPde!,
        tipo
      )
      .subscribe({
        next: (data: ConsumoComparativoTableResponse) => {
          this.consumoComparativoTableResponse = data;
          this.tabelaIot.dataSource =
            new MatTableDataSource<ConsumoComparativoTable>(
              this.consumoComparativoTableResponse.detalhes
            );
          this.tabelaIot.getListaItensPaginados();
        },
        error: () => {},
        complete: () => {},
      });
    this.estaCarregando = false;
  }
}
