import { PrimeiraLigacaoAguaServices } from 'src/app/services/primeira-ligacao-agua.service';
import { InputSelect } from '../agv-material/agv-inputs/input-select/input-select.model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Endereco,
  EnderecoBuscaPla,
  Municipio,
  Tipocivico,
  Toponimo,
} from 'src/app/core/models/enderecos/endereco';
import { EnderecoService } from 'src/app/services/enderecos.service';

@Component({
  selector: 'app-endereco-imovel',
  templateUrl: './endereco-imovel.component.html',
  styleUrls: ['./endereco-imovel.component.scss'],
})
export class EnderecoImovelComponent implements OnInit, OnChanges {
  // Actions
  @Output() enviaEnderecoPreenchido: EventEmitter<Endereco> =
    new EventEmitter();

  // Inputs
  @Input() enderecoImovel: Endereco = new Endereco();
  @Input() cepInexistente: boolean = false;

  @Input() listaMunicipios: Municipio[] = [];

  // Object
  opcoesMunicipio: InputSelect = new InputSelect();
  opcoesTipoCivico: InputSelect = new InputSelect();
  opcoesToponimo: InputSelect = new InputSelect();
  lstTipocivico: Tipocivico[];
  lstToponimo: Toponimo[];
  lstMunicipio: Municipio[];
  lstaEndereco: EnderecoBuscaPla[] = [];
  abreListaEndereco: boolean = false;

  constructor(private enderecoServices: EnderecoService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.listaMunicipios.forEach((municipio) => {
      this.opcoesMunicipio.options.push({
        value: municipio.id,
        labels: [{ value: municipio.descricao, img: false }],
      });
    });
  }

  ngOnInit(): void {
    this.setEstado();
    this.setMunicipio();
    this.setToponimo();
    this.setTipoCivico();    
  }

  setEstado() {
    this.enderecoImovel.estadoDesc = 'SP';
  }

  setMunicipio() {
    this.opcoesMunicipio.label = 'Município *';
    this.opcoesMunicipio.placeholder = 'Selecione';
    this.listaMunicipio();
  }

  listaMunicipio() {
    this.enderecoServices.listaMunicipios().subscribe({
      next: (data: Municipio[]) => {
        data.forEach((municipio) => {
          this.opcoesMunicipio.options.push({
            value: municipio.id,
            labels: [{ value: municipio.descricao, img: false }],
          });
        }); 
      },
    });
  }

  listaEndereco() {
    if (
      this.enderecoImovel.municipioId &&
      this.enderecoImovel.toponimoId &&
      this.enderecoImovel.logradouroDescr
    ) {
      this.enderecoServices
        .listaEndereco({
          municipio: this.enderecoImovel.municipioId,
          toponimo: this.enderecoImovel.toponimoId,
          endereco: this.enderecoImovel.logradouroDescr
        })
        .subscribe({
          next: (data: any) => {
            this.lstaEndereco = data;
            this.abreListaEndereco = this.lstaEndereco.length > 0;
          },
        });
    }
  }

  setToponimo() {
    this.opcoesToponimo.label = 'Tipo de endereço *';
    this.opcoesToponimo.placeholder = 'Selecione';
    this.listaToponimo();
  }

  listaToponimo() {
    this.enderecoServices.listaToponimos().subscribe({
      next: (data: Toponimo[]) => {
        this.lstToponimo = data;
        data.forEach((toponimo) => {
          this.opcoesToponimo.options.push({
            value: toponimo.toponimoId,
            labels: [{ value: toponimo.toponimoDescr, img: false }],
          });
        });
      },
    });
  }

  setTipoCivico() {
    this.opcoesTipoCivico.label = 'Tipo de número: *';
    this.opcoesTipoCivico.placeholder = 'Selecione';
    this.listaTipoCivico();
  }

  listaTipoCivico() {
    this.enderecoServices
      .listaTipoCivicos()
      .subscribe((response) => {
        response.forEach((tipoCivico) => {
          this.lstTipocivico = response;
          this.opcoesTipoCivico.options.push({
            value: tipoCivico.tipoCivicoId,
            labels: [{ value: tipoCivico.tipoCivicoDescr, img: false }],
          });
        });
      });
  }

  setDadoEndereco(e: string, campoEndereco: string) {
    this.enderecoImovel[campoEndereco] = e;
    campoEndereco === 'logradouroDescr' ? this.listaEndereco() : '';
    this.emitDadosPreenchidos();
  }

  emitDadosPreenchidos() {
    this.enviaEnderecoPreenchido.emit(this.enderecoImovel);
  }

  selecionaLogradouro(endereco: EnderecoBuscaPla) {
    this.enderecoImovel.logradouroDescr = endereco.logradouro;
    this.enderecoImovel.logradouroId = endereco.idLogradouro;
    this.enderecoImovel.bairroId = endereco.idBairro;
    this.enderecoImovel.bairroDescr = endereco.bairro;
    this.abreListaEndereco = false;
  }
}
