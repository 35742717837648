<div id="chatbot-content" class="chatbot-content">
    <div id="chatbot-content__chat" class="chatbot-content__chat">
        <img id="chatbot-content__chat__icone" class="chatbot-content__chat__icone" src="../../../../assets/imgs/png/icn_sani.png" (click)="showChat = !showChat" [ngClass]="{'open': showChat}"/>
        <span id="chatbot-content__chat__msg" class="chatbot-content__chat__msg">Precisa de ajuda?</span>
    </div>
    <div id="chatbot-content__iframe" class="chatbot-content__iframe" *ngIf="showChat">
        <div id="chatbot-content__iframe__header" class="chatbot-content__iframe__header">
            <span>SANI</span>
            <agv-icon [name]="'icn-download'"></agv-icon>
        </div>
        <iframe [src]="'https://sabesp-chat.sabesp.com.br/chat-externo/chat' | safe:'url'"></iframe>
    </div>
</div>