import {
  ElementRef,
  Component,
  ViewChild,
  Renderer2,
  OnInit,
  Input,
} from '@angular/core';
import { ConsumoComparativoResponse } from 'src/app/core/models/iotModels';

// Import register
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables, ChartDataLabels);

@Component({
  selector: 'chart-iot-diario',
  templateUrl: './chart-iot-diario.component.html',
  styleUrls: ['./chart-iot-diario.component.scss'],
})
export class ChartIotDiarioComponent implements OnInit {
  // String
  selectedOption: string = '';
  month: string = '';

  // Boolean
  exibeComparativo: boolean = false;
  projecaoParcial: boolean = false;
  exibeProjecao: boolean = false;
  exibeConsumo: boolean = false;
  exibeParcial: boolean = false;
  mesAnterior: boolean = false;
  anoAnterior: boolean = false;
  projecao: boolean = false;

  // Views
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

  // Lista
  readonly monthsList: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  readonly months: string[] = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  // Input
  @Input() consumoComparativoResponse: ConsumoComparativoResponse;
  @Input() selectedMonth: number;
  @Input() selectedYear: number;

  // Object
  chart: Chart;

  // Others
  parentCallback!: (data: any) => void;

  constructor() {}

  ngOnInit(): void {
    this.createChart();
    this.requestParentChange();
  }

  ngOnChanges(): void {
    if (this.selectedMonth) {
      this.updateChart();
    } else {
      this.selectedMonth = new Date().getMonth();
    }
    this.month = this.monthsList[this.selectedMonth - 1];
  }

  requestParentChange() {
    if (this.parentCallback) {
      this.parentCallback(this.selectedOption);
    }
  }

  registerParentCallback(callback: (data: any) => void) {
    this.parentCallback = callback;
  }

  createChart(): void {
    const chart = {
      id: 'chart-iot-diario',
      afterDraw: (chart: any) => {
        const {
          chartArea: { left, top },
          scales: { y },
        } = chart;

        const outerContainer = document.getElementById('outerContainer');
        const labelsContainer = document.getElementById('labelsContainer');

        if (outerContainer && labelsContainer) {
          labelsContainer.innerHTML = '';

          // Ajuste de layout caso seja mobile
          if (window.innerWidth <= 768) {
            y.ticks.forEach((tick: any, index: any) => {
              const yPos = y.getPixelForTick(index);

              const div = document.createElement('div');
              div.className = 'mobileVolumeLabel';
              div.innerHTML = `<div>${tick.label}</div>`;
              div.style.position = 'absolute';
              div.style.left = `${left - 55}px`;
              div.style.top = `${yPos + top - 29}px`;
              div.style.fontSize = `12px`;
              div.style.inlineSize = `max-content`;
              labelsContainer.appendChild(div);
            });
          }
        }
      },
    };

    Chart.register(chart);

    // Criação do label abaixo do gráfico
    const customLabelsPlugin = {
      id: 'customLabelsPlugin',
      afterDraw: (chart: any) => {
        const {
          ctx,
          chartArea: { bottom },
          scales: { x },
        } = chart;
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.font = '12px Satoshi';
        ctx.fillStyle = '#727373';
        const monthLabel = this.months[this.selectedMonth - 1];
        x.ticks.forEach((tick: any, index: any) => {
          const xPos = x.getPixelForTick(index);
          ctx.fillText(monthLabel, xPos, bottom + 26);

          // Adicionar borda ao redor de cada dia apenas no mobile
          if (window.innerWidth <= 768) {
            ctx.strokeStyle = '#D9D9D9'; // Cor da borda
            ctx.lineWidth = 1;
            ctx.strokeRect(xPos - 15, bottom + 10, 30, 30); // Ajustar conforme necessário
          }
        });

        ctx.restore();
      },
    };

    const canvas = document.getElementById(
      'chartIotDiario'
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    // Inserir leitura no gráfico
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              stepSize: 0.5,
              callback: function (value, index, values) {
                return value + ' m³';
              },
            },
            beginAtZero: true,
            min: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: 'nearest',
            intersect: false,
            backgroundColor: '#ffffff', // Fundo branco
            titleColor: '#000000', // Texto do título preto
            bodyColor: '#000000', // Texto do corpo preto
            footerColor: '#000000', // Texto do rodapé preto
            borderColor: '#cccccc', // Cor da borda
            borderWidth: 1, // Largura da borda
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.raw + ' m³';
                return label;
              },
            },
          },
        },
      },
      plugins: [customLabelsPlugin],
    });

    if (canvas.parentNode) {
      (canvas.parentNode as HTMLElement).style.height = '420px';
    }

    this.addHtmlElementsBelowChart();
  }

  addHtmlElementsBelowChart(): void {
    if (this.chartContainer) {
      const container = this.chartContainer?.nativeElement;
      const labels = Array.from({ length: 31 }, (_, i) => `${i + 1}`);
      labels.forEach((label) => {
        const div = document.createElement('div');
        div.className = 'custom-label';
        div.innerHTML = `<div>${label}</div><div>dia</div>`;
        container.appendChild(div);
      });
    }
  }

  updateChart(): void {
    if (!this.consumoComparativoResponse || this.consumoComparativoResponse?.consumo?.length <= 0) {
      return;
    }

    const consumoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.consumo
    );
    const comparativoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.comparativo
    );
    const previsaoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.previsao
    );

    const today = new Date(new Date().setHours(0, 0, 0, 0));

    let datasets = [];
    if (
      today.getFullYear() < this.selectedYear ||
      (today.getFullYear() === this.selectedYear &&
        today.getMonth() < this.selectedMonth)
    ) {
      this.exibeConsumo = false;
      this.exibeParcial = false;
      this.exibeProjecao = true;

      datasets.push({
        label: 'Previsão',
        data: previsaoData,
        borderColor: '#D86F23',
        backgroundColor: '#D86F23',
        fill: false,
        tension: 0.3,
        pointRadius: 0,
      });
    } else {
      this.exibeConsumo = false;
      this.exibeParcial = true;
      this.exibeProjecao = true;
      let dataWithColor: any = [];
      consumoData.forEach((value, index) => {
        dataWithColor.push(
          new Date(
            this.selectedYear,
            this.selectedMonth - 1,
            parseInt(this.consumoComparativoResponse.consumo[index]?.data),
            0,
            0,
            0
          ).getTime() < today.getTime()
            ? this.consumoComparativoResponse.consumo[index].consumo
            : this.consumoComparativoResponse.consumo[index].previsao
        );
      });
      datasets.push({
        label: 'Consumo',
        data: dataWithColor,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#D86F23',
        pointBorderWidth: 3,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        borderColor: (ctx: any) => {
          const index = ctx.dataIndex ?? ctx.datasetIndex;
          return new Date(
            this.selectedYear,
            this.selectedMonth - 1,
            parseInt(this.consumoComparativoResponse.consumo[index]?.data),
            0,
            0,
            0
          ).getTime() < today.getTime()
            ? '#00A5E4'
            : '#D86F23';
        },
        backgroundColor: (ctx: any) => {
          const index = ctx.dataIndex ?? ctx.datasetIndex;
          return new Date(
            this.selectedYear,
            this.selectedMonth - 1,
            parseInt(this.consumoComparativoResponse.consumo[index]?.data),
            0,
            0,
            0
          ).getTime() < today.getTime()
            ? '#00A5E4'
            : '#D86F23';
        },
        fill: false,
        tension: 0.3,
        pointRadius: (context: any) => {
          const index = context.dataIndex ?? context.datasetIndex;
          return new Date(
            this.selectedYear,
            this.selectedMonth - 1,
            parseInt(this.consumoComparativoResponse.consumo[index]?.data),
            0,
            0,
            0
          ).getTime() === today.getTime()
            ? 7
            : 0;
        },
        segment: {
          borderColor: (ctx: any) => {
            const { p0, p1, p1DataIndex } = ctx;
            return new Date(
              this.selectedYear,
              this.selectedMonth - 1,
              parseInt(
                this.consumoComparativoResponse.consumo[p1DataIndex]?.data
              ),
              0,
              0,
              0
            ).getTime() < today.getTime()
              ? '#00A5E4'
              : '#D86F23';
          },
          backgroundColor: (ctx: any) => {
            const { p0, p1, p1DataIndex } = ctx;
            return new Date(
              this.selectedYear,
              this.selectedMonth - 1,
              parseInt(
                this.consumoComparativoResponse.consumo[p1DataIndex]?.data
              ),
              0,
              0,
              0
            ).getTime() < today.getTime()
              ? '#00A5E4'
              : '#D86F23';
          },
        },
      });
    }

    if (
      this.consumoComparativoResponse.consumoComparativo != null &&
      this.selectedOption !== ''
    ) {
      let borderColor = '#C059FF';
      let backgroundColor = '#C059FF';
      if (this.selectedOption === 'ano') {
        borderColor = '#23D8B7';
        backgroundColor = '#23D8B7';
      }
      datasets.push({
        label: 'Comparativo',
        data: comparativoData,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        fill: false,
        tension: 0.3,
        pointRadius: 0,
        borderDash: [5, 4],
      });
    }

    if (this.chart) {
      this.chart.data.datasets = datasets;
      this.chart.update();
    } else {
      this.createChart();

      console.error('Chart instance is not defined');
    }
  }
}
